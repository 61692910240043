/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          $('.katalog-box-row').matchHeight();
          $('.katalog-box-details-footer').matchHeight();

          var changing = false;

          function changeImage($element) {
            if(!changing) {
              changing = true;

              var $image_main_parent = $element.parents('.katalog-box').find('.katalog-box-picture'),
                  $image_main = $image_main_parent.find('a'),
                  $image = $image_main.find('img'),

                  $image_thumb = $element.find('img'),
                  $image_thumb_clone = $image_thumb.clone(),
                  image_thumb_name = $image_thumb.data('name');
                  image_thumb_group = $image_thumb.data('fresco-group');
                  image_thumb_src = $image_thumb.data('image-normal');


              // ggf einfach $image_main_parent komplett ersetzen statt dem bild
              // muss mir anschauen wie das mit dem fluidbox sonst geht,
              // Test: 1. parent clonen und neu initieren...

              $image_thumb_clone.removeAttr('src');

              $image_main_parent.css({
                  'width': $image.width(),
                  'height': $image.height()
              });

              $image_main.addClass('transitioning');
              //$image_thumb_clone.addClass('transitioning');

              //$image_main.before($image);
              $image_thumb_clone.wrap( "<a href='"+image_thumb_src+"' data-fresco-group=\""+image_thumb_group+"\" data-fresco-options=\"ui: 'inside'\" data-fresco-caption=\""+image_thumb_name+"\" class='fresco transitioning'></a>" );

              $image_main.before($image_thumb_clone.parent());

              $image_thumb_clone.attr('src', image_thumb_src).bind('onreadystatechange load', function(){
                  if (this.complete) {
                      new_image_height = $image_thumb_clone.height();
                      $image_main_parent.animate({height: new_image_height+"px"}, 500);

                      $image_main.fadeOut(400, function() {
                          $image_main.remove();

                          $image_thumb_clone.parent().removeClass('transitioning');
                          $image_main_parent.removeAttr('style');

                          $.fn.matchHeight._maintainScroll = true;
                          $.fn.matchHeight._update();

                          changing = false;
                      });
                  }
              });

              $element.addClass('active').siblings().removeClass('active');
            }
          }


          $('.katalog-thumbnail-list li').on('click', function() {
              changeImage($(this));
          });


          $(".katalog-thumbnail-list").hoverIntent({
              over: function() {
                changeImage($(this));
              },
              out: function() {},
              selector: 'li'
          });

          $('.katalog-box-outer').matchHeight();

          $('.thumb-container').matchHeight();

          $('[data-toggle="tooltip"]').tooltip();


          $('[data-toggle="login"], body:not(.logged-in) .menu-downloads:not(.active) a').on('click', function(e) {
              e.preventDefault();
              $('#loginModal').modal('show');
          });

          $('form#loginModal').on('submit', function(e){
              var $this = $(this),
                  username = $this.find('#username').val(),
                  password = $this.find('#password').val(),
                  remember = $this.find('#remember:checked').val();
                  referer = $this.find('#referer').val();

              if(username !== "" && password !== "") {
                  $.ajax({
                      url: $tq.ajax_url,
                      method: "GET",
                      data: {
                          action : "login",
                          security: $tq.ajax_nonce,
                          'username': username,
                          'password': password,
                          'remember': remember
                      },
                      dataType: "json",
                      success: function(data){
                          if(data.login === true) {
                              // location.reload();
                              window.location.href = "/downloads";
                          }
                      },
                      error: function(data){
                          $this.find('.message').html("<div class=\"alert alert-danger\">"+data.responseJSON.message+"</div>");
                          $this.find('input[type="text"]').parent().addClass('has-error');
                      }
                  });
              } else {
                  $this.find('.message').html("<div class=\"alert alert-danger\">Bitte Felder ausfüllen.</div>");
                  $this.find('input[type="text"]').parent().addClass('has-error');
              }

              e.preventDefault();
          });


          if($("#map").length) {
              $.ajax({
                  url: $tq.ajax_url,
                  method: "GET",
                  data: {
                      action : "remote_map",
                      security: $tq.ajax_nonce
                  },
                  dataType: "json",
                  success: function(data) {
                      $("#map").tqHaendlersuche(data.data);
                  }
              });
          }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

          function resize() {
              var $bv = $('#backgroundvideo'),
                  width = $(window).width(),
                  height = $(window).height(),
                  ratio = 16/9, pWidth, pHeight;
              if (width / ratio < height) {
                  pWidth = Math.ceil(height * ratio);
                  $bv.width(pWidth).height(height).css({left: (width - pWidth) / 2, top: 0});
              } else {
                  pHeight = Math.ceil(width / ratio);
                  $bv.width(width).height(pHeight).css({left: 0, top: (height - pHeight) / 2});
              }
          }

          resize();

          $(window).resize(function() {
              resize();
          });

          $('.fs-toggle').on('click', function(event) {
              event.preventDefault();
              $('body').toggleClass('fs-on');

          });

          var $vc = $('#videocontainer'),
              player = videojs('videocontainer', {
                    "autoplay": true,
                    "loop": true,
                    "muted" : true,
                    "preload": "auto"
                }, function() {
                  player.play();
                  $vc.on('click', function() {
                      if (player.paused()) {
                          player.play();
                      } else {
                          player.pause();
                      }
                  });
              });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_slider': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('#slides').superslides();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
