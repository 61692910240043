/* Taquiri Google Map Plugin */

(function($){
    $.fn.tqHaendlersuche = function (locations, options, key) {
        "use strict";

        var $container = $(this),
            $window = $(window),
            apikey = key || "AIzaSyD0J1k4cYxoLukb6RofrDLwhGEkICvJOYE";

        function init() {

            var settings = $.extend({
                scrollwheel: false,
                zoomControl: true,
                streetViewControl: false,
                scaleControl: true,
                zoom: 6,
                draggable: true,
                disableDefaultUI: true,
                styles: [
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e9e9e9"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dedede"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#333333"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    }
]            }, options );

            var map = new google.maps.Map($container[0], settings),
                bounds = new google.maps.LatLngBounds(),
                infowindow = new google.maps.InfoWindow({ content: "loading..." }),
                position, markers = [], marker, i;


            $.each(locations, function(i, loc) {

                if ( loc.lat && loc.lng ){

                    var title = loc.name || loc.title,
                        route = loc.route || "https://maps.google.com?saddr=Current+Location&daddr="+loc.lat+","+loc.lng,
                        content = '<div class="infoWindow"><strong>'+title+'</strong><br>'+loc.addr+'<br><a href="'+route+'"><i class="fa fa-road"></i> Routenplaner</a></div>';

                    position = new google.maps.LatLng(loc.lat, loc.lng);

                    marker = new google.maps.Marker({
                        position: position,
                        title: title,
                        icon: loc.icon || undefined,
                        map: map,
                        addr: loc.addr,
                        id: "m"+i,
                    });

                    markers.push(marker);

                    // Marker Popup Inhalt setzen und bei Click öffnen
                    google.maps.event.addListener(marker, 'click', function() {
                        infowindow.setContent(content);
                        infowindow.open(map, this);
                    });

                    if ( locations.length > 1 ) {
                        bounds.extend(position);
                        map.fitBounds(bounds);
                    } else {
                        map.setCenter(position);
                        google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
                            google.maps.event.trigger(marker,'click');
                        });
                    }

                }

            });

            // Google maps responsive
            google.maps.event.addDomListener(window, "resize", function() {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });

            var searchmarker = "";


            // Add circle overlay and bind to marker
            var circle = new google.maps.Circle({
                map: map,
                radius: 30000,
                clickable: false,
                fillColor: '#fff',
                fillOpacity: 0,
                strokeColor: '#313131',
                strokeOpacity: 0,
                strokeWeight: 0.8,
            });

            google.maps.Circle.prototype.contains = function(latLng) {
                return this.getBounds().contains(latLng) && google.maps.geometry.spherical.computeDistanceBetween(this.getCenter(), latLng) <= this.getRadius();
            };

            google.maps.Map.prototype.panToWithOffset = function(latlng, offsetX, offsetY) {
                var map = this;
                var ov = new google.maps.OverlayView();
                ov.onAdd = function() {
                    var proj = this.getProjection();
                    var aPoint = proj.fromLatLngToContainerPixel(latlng);
                    aPoint.x = aPoint.x+offsetX;
                    aPoint.y = aPoint.y+offsetY;
                    map.panTo(proj.fromContainerPixelToLatLng(aPoint));
                };
                ov.draw = function() {};
                ov.setMap(this);
            };


            function find_closest_marker( event ) {
                var pos     = event,
                    closest;

                if(!pos || !markers){
                    return;
                }

                $.each(markers,function(){
                    var distance = google.maps.geometry.spherical
                                  .computeDistanceBetween(this.getPosition(),pos);
                    if(!closest || closest.distance > distance){
                        closest = {marker:this,
                               distance:distance};
                    }
                });
                if(closest){
                    //closest.marker will be the nearest marker, do something with it
                    //here we simply trigger a click, which will open the InfoWindow
                    google.maps.event.trigger(closest.marker,'click');
                }
                return closest;
            }

            //google.maps.event.addListener(map, 'click', find_closest_marker);


            function prettydistance(distance) {
                var pd;
                if(distance > 1000) {
                    var pdbase = (distance / 1000).toFixed(1).replace('.', ',');
                    pd = "ca. "+pdbase+"km entfernt";
                } else if(distance < 100) {
                    pd = "weniger als 100m";
                } else {
                    pd = "ca. "+Math.ceil(distance/100)*100+"m entfernt";
                }
                return pd;
            }

            function display_results(pos) {

                        $container.css('background-image', 'none');
                        map.panTo(pos);
                        map.setZoom(9);
                        map.panBy(-100,0);

                        circle.setCenter(pos);

                        var closest = find_closest_marker(pos);

                        var proximity = false;

                        // $(".panel-map .list-group").empty();
                        if(searchmarker) {
                            searchmarker.setMap(null);
                        }

                        searchmarker = new google.maps.Marker({
                            position: pos,
                            title: 'Mein Standort',
                            icon: '/uploads/suri-pin-where.png',
                            map: map
                        });

                        var content = '<div class="infoWindow"><strong>Ich bin hier!</strong></div>';

                        google.maps.event.addListener(searchmarker, 'click', function() {
                            infowindow.setContent(content);
                            infowindow.open(map, this);
                        });

                        $(".panel-map .list-group").fadeOut(1000, function() {
                            $(this).empty();
                            //alert(map.getBounds());
                            var active = "", $html = "", appendage = [], counter = 0;


                            $.each(markers, function(i, marker) {
                                if(circle.contains(marker.getPosition())) {
                                    active = (this === closest.marker) ? " active" : "";

                                    var md = google.maps.geometry.spherical.computeDistanceBetween(this.getPosition(), pos);

                                    //if ( counter < 5 ) {
                                        $html  = "<div class=\"list-group-item"+active+"\" data-marker=\""+marker.id+"\">";
                                        $html += "<h4 class=\"list-group-item-heading\">"+marker.title+"</h4>";
                                        $html += "<p class=\"list-group-item-text list-group-item-data\">";
                                        $html += marker.addr+" <br><small>Distanz "+prettydistance(md)+"</small>";
                                        $html += "</p>";
                                        $html += "</div>";
                                        appendage.push({"html": $html, "distance": md});
                                    //}
                                    //proximity = true;
                                    //return false;
                                    counter++;
                                }
                            });
                            var hmarker = [];

                            appendage.sort(function(a, b){return (a.distance - b.distance);});

                            $.each(appendage, function(i, newmarker) {
                                hmarker.push(newmarker.html);
                            });

                            $(".panel-map .list-group").append(hmarker.join(''));
                            $(".panel-scroll").scrollTop();
                            $(this).fadeIn(1000);

                            $("[data-marker]").on("click", function (e) {
                                $(this).siblings().removeClass("active");
                                $(this).addClass("active");

                                var htmlid = $(this).data("marker");
                                $.each(markers, function(i, marker) {
                                    if(marker.id === htmlid) {
                                        google.maps.event.trigger(marker,'click');
                                        map.panToWithOffset(marker.position, -100, 0);
                                        //map.panTo(marker.position);
                                        //map.panBy(-100,0);
                                        //$tq.log(marker.position);
                                    }
                                });
                                //map.setZoom(8);

                            });

                        });

                        if(proximity === false) {
                            //$tq.log("no proximity");
                            //var pbounds = google.maps.LatLngBounds();
                           // pbounds.extend(closest.position);
                            //map.fitBounds(pbounds);
                        }

            }

            function codeAddress() {
                var geocoder = new google.maps.Geocoder();
                var address = document.getElementById("address").value;

                address = (address !== "") ? address : "63073 Offenbach";
                //var circle;
                geocoder.geocode( { 'address': address }, function(results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        display_results(results[0].geometry.location);
                    } else {
                        $tq.log("Geocode was not successful for the following reason: " + status);
                    }
                });
            }

            $("#geocode").on("submit", function(e) {
                codeAddress();
                $container.parent().removeClass('map-phase1');
                e.preventDefault();
            });

            $("#geolocation").on("click", function() {
                if(navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        var pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                        display_results(pos);
                        $container.parent().removeClass('map-phase1');
                    });
                }
            });


        }

        // Google Maps
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "//maps.google.com/maps/api/js?key="+apikey+"&callback=tqHaendlersucheInit";

        if($container.length > 0 && $("[src^='//maps']").length < 1) {
            document.body.appendChild(script);
        }
        window.tqHaendlersucheInit = function() { init(); };

    };
})(jQuery);